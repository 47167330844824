import * as React from "react";

function SvgNoIcon(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    />
  );
}

export default SvgNoIcon;
