import * as React from "react";

function SvgExternalLink(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M23.497 7.75V1h-6.75m6.75 0l-10.94 10.939M18.5 13.5v8A1.5 1.5 0 0117 23H2.5A1.5 1.5 0 011 21.5V7a1.5 1.5 0 011.5-1.5h8.495"
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
}

export default SvgExternalLink;
