import * as React from "react";

function SvgMissingImage(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <path d="M17.5 20.5a.375.375 0 110 .75.375.375 0 010-.75m0-2.25v-3" />
        <path d="M23.313 20.932a1.774 1.774 0 01-1.587 2.568h-8.452a1.774 1.774 0 01-1.587-2.568l4.226-8.451a1.774 1.774 0 013.174 0l4.226 8.451zM7.75 20.5H2.5A1.5 1.5 0 011 19V2.5A1.5 1.5 0 012.5 1h10.629a1.5 1.5 0 011.06.439l2.872 2.872a1.5 1.5 0 01.439 1.06V8.5" />
        <path d="M5.5 5.875a.375.375 0 110 .75.375.375 0 010-.75m7.606 5.195L11.5 8.5a.75.75 0 00-1.238-.014l-2 2.851-1.232-.987a.75.75 0 00-1.093.169L4 14.5h6" />
      </g>
    </svg>
  );
}

export default SvgMissingImage;
